<template>
  <v-footer id="core-footer" absolute height="30">
    <div class="footer-items"></div>
    <v-spacer />
  </v-footer>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
