export const set = (property) => (state, payload) => (state[property] = payload);
export const toggle = (property) => (state) => (state[property] = !state[property]);

const state = {
  drawer: null,
  breadcrumbs: null,
  backURL: '',
};

const getters = {};

const mutations = {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setBackURL: set('backURL'),
  toggleDrawer: toggle('drawer'),
  setBreadcrumbs: set('breadcrumbs'),
};

const actions = {
  SET_BREADCRUMBS({ commit }, payload) {
    commit('setBreadcrumbs', payload);
  },
};

const app = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default app;
