<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    mobile-breakpoint="991"
    width="260"
    :src="background"
  >
    <v-list dense nav class="py-0">
      <v-list-item two-line>
        <v-list-item-avatar>
          <img :src="logo" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <template v-for="(item, i) in items">
        <v-list-item
          v-if="checkPermission($router.resolve(item.route).route.meta.guard)"
          :key="i"
          :to="item.route"
          :class="item.class"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Application',
    },
    logo: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {
    ...mapState('app', ['color']),
    ...mapGetters('auth', ['checkPermission']),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
  },
};
</script>

<style lang="scss">
.v-navigation-drawer {
  .v-list {
    background: rgba(27, 27, 27, 0.74);
    height: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .v-list-item {
      -ms-flex: unset;
      flex: unset;
    }
  }
  .v-list:not(.v-select-list) {
    background: rgba(27, 27, 27, 0.74);
  }
  .settings {
    margin-top: auto;
    margin-bottom: 17px;
    display: flex;
  }
}

.v-divider {
  margin-bottom: 10px;
}
.v-list-item__avatar {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.row {
  margin: 0px;
}
</style>
