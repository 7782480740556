import Vue from 'vue';
import VueWait from 'vue-wait';

Vue.use(VueWait);

new Vue({
  // your vue config
  wait: new VueWait(),
});

const wait = new VueWait({
  useVuex: true, // You must pass this option `true` to use Vuex
});

export default wait;
