import Home from './routes/home/Index.vue';
import AgreementsGenerator from './routes/agreementsGenerator/Index.vue';
import Users from './routes/users/Index.vue';
import UserEdit from './routes/users/Edit/Index.vue';
import UserProfile from './routes/profile/Index.vue';
import Settings from './routes/settings/Index.vue';
import Email from './routes/settings/email/Index.vue';
import Templates from './routes/settings/templates/Index.vue';
import Reminder from './routes/settings/reminder/Index.vue';

let appRoutes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },
  {
    path: 'generator',
    name: 'generator',
    meta: {
      label: 'Генератор договоров',
    },
    component: AgreementsGenerator,
  },
  {
    path: 'users',
    name: 'Users',
    meta: {
      label: 'Сотрудники',
      guard: 'USERS',
    },
    component: Users,
  },
  {
    path: 'users/:id',
    name: 'EditUser',
    component: UserEdit,
    meta: {
      guard: 'USER-EDIT',
    },
  },
  {
    path: 'user-profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: 'settings',
    name: 'Settings',
    meta: {
      guard: 'SETTINGS',
    },
    component: Settings,
    children: [
      {
        path: 'email',
        component: Email,
        meta: {
          guard: 'SETTINGS',
        },
      },
      {
        path: 'templates',
        component: Templates,
        meta: {
          guard: 'SETTINGS',
        },
      },
      {
        path: 'reminder',
        component: Reminder,
        meta: {
          guard: 'SETTINGS',
        },
      },
    ],
  },
];

export default appRoutes;
