<template>
  <div>
    <core-toolbar />
    <core-drawer
      :background="require(`@/assets/images/sidebar.jpg`)"
      title="Генератор договоров"
      :logo="require(`@/assets/logo.png`)"
      :items="sidebarItems"
    />
    <core-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  data: () => ({
    sidebarItems: [
      {
        icon: 'mdi-file-document-multiple',
        text: 'Генератор договоров',
        route: '/generator',
      },
      {
        icon: 'mdi-account',
        text: 'Сотрудники',
        route: '/users',
      },
      {
        icon: 'mdi-settings',
        text: 'Настройки',
        route: '/settings',
        class: 'settings', // должен быть последним, настройки стилей что б вниз
      },
    ],
  }),
};
</script>
