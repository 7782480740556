<template>
  <div>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
      ref="menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          ref="DateTextField"
          :label="label"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-model="dateText"
          v-on="on"
          :readonly="readonly"
          :rules="validationRules"
          autocomplete="off"
          type="search"
          @blur="parseData"
          @keydown.enter="onEnter($event)"
          @keydown.tab="onTab"
          v-maska="'##.##.####'"
          name="datepicker"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        locale="ru-ru"
        @input="pickDate($el)"
        min="1900-01-01"
        max="2100-01-01"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'datepickerFormatted',
  data() {
    return {
      date: null,
      dateMenu: false,
      dateText: '',
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    startDateNow: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    validationRules() {
      if (this.required) {
        return [(v) => !!v || this.label + ' обязательное поле!'];
      }
      return [];
    },
  },
  created() {
    if (this.startDateNow) {
      //this.date = new Date().toISOString().substr(0, 10);
      this.date = moment().format('YYYY-MM-DD');
      this.dateText = moment(this.date).format('DD.MM.YYYY');
      this.$emit('input', this.dateText);
    }
  },
  methods: {
    pickDate(el) {
      this.dateMenu = false;
      this.dateText = moment(this.date).format('DD.MM.YYYY');
      this.$emit('input', this.dateText);
      const element = el.getElementsByTagName('input')[0];
      const inputs = Array.from(element.form.querySelectorAll('input[type="search"]'));
      const index = inputs.indexOf(element);
      if (index < inputs.length - 1) {
        if (inputs[index + 1].name === 'datepicker') {
          inputs[index + 1].focus();
          inputs[index + 1].click();
        } else {
          inputs[index + 1].focus();
        }
      } else {
        inputs[index].blur();
      }
    },
    parseData() {
      if (this.dateText && this.dateText.length === 10) {
        let mindate = moment('1900-01-01', 'YYYY-MM-DD');
        let maxdate = moment('2100-01-01', 'YYYY-MM-DD');
        let currentDate = moment(this.dateText, 'DD.MM.YYYY').format('YYYY-MM-DD');
        let currentDateForCompare = moment(currentDate, 'YYYY-MM-DD');

        if (currentDateForCompare > mindate && currentDateForCompare < maxdate) {
          this.date = currentDate;
          this.$emit('input', this.dateText);
        } else {
          this.date = null;
          this.dateText = null;
        }
      } else {
        this.date = null;
        this.dateText = null;
      }
    },
    parseDataOutside(outsideDate) {
      this.dateText = outsideDate;
      this.parseData();
    },
    onEnter(e) {
      this.$refs.DateTextField.blur();
      this.parseData();
      this.dateMenu = false;
      this.focusNextInput(e);
    },
    onTab() {
      this.parseData();
      this.dateMenu = false;
    },
    focusNextInput(e) {
      e.preventDefault();
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="search"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length - 1) {
        if (inputs[index + 1].name === 'datepicker') {
          inputs[index + 1].focus();
          inputs[index + 1].click();
        } else {
          inputs[index + 1].focus();
        }
      } else {
        inputs[index].blur();
      }
    },
  },
};
</script>

<style lang="css"></style>
