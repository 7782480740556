import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const requireComponent = require.context('@/components', true, /\.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(
      fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .replace('Index', '')
    )
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});
