import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Vuetify from 'vuetify/lib';
import './components/components';
import '@/utils/app/components/';
//import initPlugins from '@/utils/plugins';
import '@/utils/plugins/notifier/toastr';
import '@/utils/plugins/vue-wait/index';
import wait from '@/utils/plugins/vue-wait/index';

Vue.use(Vuetify);
const vuetify = new Vuetify({});

// const moment = require('moment');
// require('moment/locale/ru');
// Vue.use(require('vue-moment'), {
//   moment,
// });

import Maska from 'maska';
Vue.use(Maska);

import VueCurrencyInput from 'vue-currency-input';
Vue.use(VueCurrencyInput);

const app = {
  router,
  store,
  vuetify,
  wait,
  render: (h) => h(App),
};

//initPlugins(app);

new Vue(app).$mount('#app');
