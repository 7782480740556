import Vue from 'vue';
// import plugin
import VueToastr from 'vue-toastr';
// use plugin
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
  defaultTimeout: 4000,
  defaultPosition: 'toast-top-center',
  defaultStyle: { opacity: 1 },
  defaultClassNames: ['toastr-custom'],
});
