<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-row no-gutters>
          <v-col cols="12" class="pt-0 pb-0">
            <v-card class="mr-1 mb-1">
              <v-tabs>
                <v-tab>Переменные</v-tab>
                <v-tab-item>
                  <v-card-text>
                    <div>Переменные обрамляються символами <b>{ }</b></div>
                    <div>
                      Поддерживаются условия, например: <b>{#SC &lt; 2}</b>Продавця<b
                        >{/}{#SC &gt; 1}</b
                      >Продавців<b>{/}{#SC==="1"}</b>Текст<b>{/}</b>
                    </div>
                    <div>Поддерживаются отрицание, например: <b>{#!NFH}</b>Текст<b>{/}</b></div>
                    <div>
                      Поддерживаются массивы, например: <b>{#SAR}{SF}</b>,
                      <b>{SD} {SM} {SY}</b> року народження, рнокпп
                      <b>{SR}{/SAR}</b>
                    </div>
                  </v-card-text>
                  <v-data-table
                    :search="searchParams"
                    no-data-text="Ничего не найдено"
                    no-results-text="Ничего не найдено"
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="8" sm="4">
                          <v-text-field
                            prepend-inner-icon="mdi-magnify"
                            v-model="searchParams"
                            label="Фильтр"
                            clearable
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.Symbol="{ item }">
                      <v-chip :color="item.Color">
                        {{ item.Symbol }}
                      </v-chip>
                    </template>
                    <template v-slot:item.Field="{ item }">
                      <v-chip :color="item.Color">
                        {{ item.Field }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab>Поля</v-tab>
                <v-tab-item>
                  <v-data-table
                    :search="searchFields"
                    no-data-text="Ничего не найдено"
                    no-results-text="Ничего не найдено"
                    :headers="headersFields"
                    :items="itemsFields"
                    hide-default-footer
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="8" sm="4">
                          <v-text-field
                            prepend-inner-icon="mdi-magnify"
                            v-model="searchFields"
                            label="Фильтр"
                            clearable
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.Field="{ item }">
                      <v-chip :color="item.Color">
                        {{ item.Field }}
                      </v-chip>
                    </template>
                    <template v-slot:item.Required="{ item }">
                      <span style="color: red" v-if="item.Required === 'Обязательное поле'">
                        {{ item.Required }}
                      </span>
                      <span
                        style="color: green"
                        v-else-if="item.Required === 'Необязательное поле'"
                      >
                        {{ item.Required }}
                      </span>
                      <span
                        style="color: gray"
                        v-else-if="item.Required === 'Генерируется системой'"
                      >
                        {{ item.Required }}
                      </span>
                      <span style="color: blue" v-else>
                        {{ item.Required }}
                      </span>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';
import params from './params.json';
import fields from './fields.json';
export default {
  data() {
    return {
      searchParams: '',
      searchFields: '',
      itemsPerPage: 999,
      headers: [
        { text: 'Символ', value: 'Symbol' },
        { text: 'Поле', value: 'Field' },
        { text: 'Формат', value: 'Format' },
        { text: 'Пример', value: 'Example' },
      ],
      headersFields: [
        { text: 'Поле', value: 'Field' },
        { text: 'Обязательность поля', value: 'Required' },
        { text: 'Комментарий', value: 'Comment' },
      ],
      items: params,
      itemsFields: fields,
    };
  },
  methods: {
    async setBreadcrumbs() {
      await this.$store.dispatch('app/SET_BREADCRUMBS', [
        { text: 'Главная', to: '/', disabled: false },
        { text: 'Настройки', to: '/settings', disabled: false },
        { text: 'Памятка', disabled: true },
      ]);
    },
  },
  created() {
    this.setBreadcrumbs();
  },
  beforeCreate() {
    let call = async () => {
      await HTTP.get('auth/empty').then(
        () => {},
        (error) => {
          if (error.response.data.Error) {
            throw error.response.data.Error;
          }
          throw error;
        }
      );
    };
    call();
  },
};
</script>
<style lang="css">
.invisible {
  opacity: 0;
}
</style>
