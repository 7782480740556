<template>
  <v-text-field
    ref="input"
    :value="formattedValue"
    v-currency="options"
    :label="label"
    :required="required"
    :autocomplete="autocomplete"
    :type="type"
    :prepend-icon="prependIcon"
    :rules="rules"
    v-on="inputListeners"
    :readonly="readonly"
    maxlength="19"
  ></v-text-field>
</template>

<script>
export default {
  name: 'currencyInput',
  props: {
    value: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Array,
      default: () => [],
    },
    autocomplete: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  computed: {
    inputListeners: function () {
      let vm = this;
      // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
      return Object.assign(
        {},
        // Мы добавляем все слушатели из родителя
        this.$listeners,
        // Затем мы можем добавить собственные слушатели или
        // перезаписать поведение некоторых существующих.
        {
          // Это обеспечит, что будет работать v-model на компоненте
          input: function (value) {
            if (value === null) {
              vm.$emit('input', null);
              vm.formattedValue = value;
              return;
            }
            vm.$emit('input', vm.$ci.getValue(vm.$refs.input));
            vm.formattedValue = value;
          },
          change: function (value) {
            vm.$emit('change', vm.$ci.getValue(vm.$refs.input));
            vm.formattedValue = value;
          },
        }
      );
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
  },
};
</script>
<style scoped>
.v-input >>> input {
  font-weight: bold;
}
</style>
