<template>
  <v-row class="pr-3 pl-3">
    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
      <v-select
        v-model="mask"
        :items="[
          {
            text: '(###) ###-##-##',
            value: '(###) ###-##-##',
          },
          {
            text: '(###-##) #-##-##',
            value: '(###-##) #-##-##',
          },
          {
            text: 'Без маски',
            value: '################',
          },
        ]"
        :label="maskLabel"
      />
    </v-col>
    <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
      <v-text-field
        :label="label"
        v-model="getValue"
        v-mask="mask"
        :rules="maskRules"
        @input="handleInput"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },
  name: 'tel',
  data() {
    return {
      localValue: '',
      mask: '(###) ###-##-##',
      maskRules: [
        (v) => {
          let maxLength;
          switch (this.mask) {
            case '(###) ###-##-##':
              maxLength = 15;
              break;
            case '(###-##) #-##-##':
              maxLength = 16;
              break;
            case '################':
              maxLength = 1;
              break;
          }
          if (!v) return true;
          return (v && v.length >= maxLength) || 'Введите номер полностью';
        },
      ],
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maskLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.localValue);
    },
  },
};
</script>

<style scoped></style>
