// import Vue from 'vue';
// import api from '@/config/api';
// import auth from '@/config/auth';
import { HTTP, HTTPLogin } from '@/utils/plugins/axios/axios';

// const getUrl = url => {
//   let urlArray = [];
//   if (auth.api) {
//     urlArray.push(auth.api);
//   } else {
//     urlArray.push(api.url);
//     if (api.path.prefix) {
//       urlArray.push(api.path.prefix);
//     }
//   }
//   if (auth.prefix) {
//     urlArray.push(auth.prefix);
//   }
//   urlArray.push(url);
//   return urlArray.join('/');
// };

const actions = {
  LOGIN({ commit }, credential) {
    return new Promise((resolve, reject) => {
      HTTPLogin.post('auth/login', credential)
        .then((response) => {
          commit('LOGIN', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  LOGOUT({ commit }) {
    commit('logout');
    HTTP.post('auth/logout');
  },
  NETWORK_ERROR({ commit }, isError) {
    commit('networkError', isError);
  },
  REFRESH_TOKEN({ commit }, data) {
    commit('REFRESH_TOKEN', data);
  },
  // getUser({ commit }) {
  //   return new Promise(resolve => {
  //     const path = auth.paths.getUser || 'user';
  //     Vue.http
  //       .get(getUrl(path))
  //       .then(response => response.json())
  //       .then(response => {
  //         if ([400, 401, 403].includes(response.status)) {
  //           commit('logout');
  //         }
  //         resolve();
  //       });
  //   });
  // },
  // editUser({ commit, dispatch }, data) {
  //   const path = auth.paths.editUser || 'user';
  //   Vue.http.post(getUrl(path), data).then(
  //     response => {
  //       let result = response.body;
  //       commit('editUser', result);
  //     },
  //     error => {
  //       dispatch('openAlertBox', ['alertError', error.statusText], {
  //         root: true
  //       });
  //     }
  //   );
  // },
  // editPassword({ commit, dispatch }, data) {
  //   const path = auth.paths.changePassword || 'password';
  //   Vue.http.post(getUrl(path), data).then(
  //     response => {
  //       let result = response.body;
  //       commit('editPassword', result);
  //     },
  //     error => {
  //       dispatch('openAlertBox', ['alertError', error.statusText], {
  //         root: true
  //       });
  //     }
  //   );
  // }
};

export default actions;
