<template>
  <div>
    <v-text-field
      v-model="sellerDataBlockLocal.sellerFIO"
      :counter="100"
      :label="fioLabel"
      required
      :rules="sellerFIORules"
      autocomplete="off"
      type="search"
      prepend-icon="mdi-account"
      maxlength="100"
      @input="callback"
      @keydown.enter="focusNextInput($event)"
      @keydown.tab="focusNextInput($event)"
    ></v-text-field>
    <utils-datepicker-formatted
      :label="birthdayLabel"
      v-model="sellerDataBlockLocal.sellerBirthday"
      required
      @input="callback"
    ></utils-datepicker-formatted>
    <v-text-field
      v-model="sellerDataBlockLocal.sellerRNOKPP"
      :counter="10"
      :label="rnokppLabel"
      required
      :rules="sellerRNOKPPRules"
      autocomplete="off"
      type="search"
      prepend-icon="mdi-qrcode"
      v-maska="'##########'"
      maxlength="10"
      @input="callback"
      @keydown.enter="focusNextInput($event)"
      @keydown.tab="focusNextInput($event)"
    ></v-text-field>
    <v-text-field
      v-model="sellerDataBlockLocal.sellerAddress"
      :counter="150"
      :label="addressLabel"
      required
      :rules="sellerAddressRules"
      autocomplete="off"
      type="search"
      prepend-icon="mdi-home-map-marker"
      maxlength="150"
      @input="callback"
      @keydown.enter="focusNextInput($event)"
      @keydown.tab="focusNextInput($event)"
    ></v-text-field>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/uk';

export default {
  name: 'sellerBlock',
  data() {
    return {
      sellerDataBlockLocal: {
        sellerFIO: '',
        sellerBirthday: '',
        sellerRNOKPP: '',
        sellerAddress: '',
        d: '',
        m: '',
        y: '',
      },
      sellerFIORules: [
        (v) => !!v || 'ФИО Продавца' + this.sellerNumberText + ' - обязательное поле!',
      ],
      sellerRNOKPPRules: [
        (v) => !!v || 'РНОКПП Продавца' + this.sellerNumberText + ' - обязательное поле!',
        (v) => (v && v.length === 10) || 'Не менее 10 цифр',
      ],
      sellerAddressRules: [
        (v) =>
          !!v || 'Адрес регистрации Продавца' + this.sellerNumberText + ' - обязательное поле!',
      ],
    };
  },
  props: {
    sellerNumber: {
      type: String,
      required: false,
    },
    overrideShowSellerNumber: {
      type: Boolean,
      required: false,
    },
  },
  created() {},
  computed: {
    sellerNumberText() {
      if (
        this.sellerNumber === undefined ||
        (this.sellerNumber === '1' && !this.overrideShowSellerNumber)
      ) {
        return '';
      } else {
        return ' №' + this.sellerNumber;
      }
    },
    fioLabel() {
      return 'ФИО Продавца' + this.sellerNumberText;
    },
    birthdayLabel() {
      return 'Дата рождения продавца' + this.sellerNumberText;
    },
    rnokppLabel() {
      return 'РНОКПП Продавца' + this.sellerNumberText;
    },
    addressLabel() {
      return 'Адрес регистрации Продавца' + this.sellerNumberText;
    },
  },
  methods: {
    callback() {
      if (
        this.sellerDataBlockLocal.sellerBirthday !== '' &&
        this.sellerDataBlockLocal.sellerBirthday !== null
      ) {
        this.sellerDataBlockLocal.d = this.dateToWords(
          this.sellerDataBlockLocal.sellerBirthday
        ).day;
        this.sellerDataBlockLocal.m = this.dateToWords(
          this.sellerDataBlockLocal.sellerBirthday
        ).month;
        this.sellerDataBlockLocal.y = this.dateToWords(
          this.sellerDataBlockLocal.sellerBirthday
        ).year;
      }

      this.$emit('dataBlockUpdate', this.sellerDataBlockLocal);
    },
    focusNextInput(e) {
      e.preventDefault();
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="search"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length - 1) {
        if (inputs[index + 1].name === 'datepicker') {
          inputs[index + 1].focus();
          inputs[index + 1].click();
        } else {
          inputs[index + 1].focus();
        }
      } else {
        inputs[index].blur();
      }
    },
    dateToWords(stringDate) {
      let d = moment(stringDate, 'DD.MM.YYYY');
      let day = d.format('DD');
      let month = d.locale('uk').format('D MMMM');
      month = month.replace(/[0-9 ]/g, '');
      let year = d.format('YYYY');
      return { day, month, year };
    },
  },
};
</script>

<style lang="css"></style>
