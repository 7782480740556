import { HTTP } from '@/utils/plugins/axios/axios';

const state = {
  users: [],
  user: {},
};

const getters = {
  USERS: (state) => {
    return state.users;
  },
  USER: (state) => {
    return state.user;
  },
};

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload;
  },

  SET_USER: (state, payload) => {
    state.user = payload;
  },
};

const actions = {
  ADD_NEW_USER: async (context, newUser) => {
    await HTTP.post('new_user', newUser).then(
      () => {},
      (error) => {
        if (error.response.data.Error) {
          throw error.response.data.Error;
        }
        throw error;
      }
    );
  },
  GET_USERS: async ({ commit }) => {
    await HTTP.get('users').then(
      (response) => {
        commit('SET_USERS', response.data);
      },
      (error) => {
        if (error.response.data.Error) {
          throw error.response.data.Error;
        }
        throw error;
      }
    );
  },
  GET_USER_BY_ID: async ({ commit }, userId) => {
    await HTTP.get('users/' + userId).then(
      (response) => {
        commit('SET_USER', response.data);
      },
      (error) => {
        if (error.response.data.Error) {
          throw error.response.data.Error;
        }
        throw error;
      }
    );
  },

  CHANGE_USER_DATA: async (context, payload) => {
    let { id, value, field } = payload;
    await HTTP.post('/users/' + id, {
      [field]: value,
    }).catch((error) => {
      if (error.response.data.Error) {
        throw error.response.data.Error;
      }
      throw error;
    });
  },
  DELETE_USER: async (_, id) => {
    await HTTP.post(`/users/delete/` + id).catch((error) => {
      if (error.response.data.Error) {
        throw error.response.data.Error;
      }
      throw error;
    });
  },
  EMPTY_USERS: ({ commit }) => {
    commit('SET_USERS', []);
  },
};

const users = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default users;
