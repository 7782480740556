import { HTTP } from '@/utils/plugins/axios/axios';

const state = {
  emailSettings: [],
};

const getters = {
  EMAIL_SETTINGS: (state) => {
    return state.emailSettings;
  },
};

const mutations = {
  SET_EMAIL_SETTINGS: (state, payload) => {
    state.emailSettings = payload;
  },
};

const actions = {
  GET_EMAIL_SETTINGS: async ({ commit }) => {
    await HTTP.get('settings/email').then(
      (response) => {
        commit('SET_EMAIL_SETTINGS', response.data);
      },
      (error) => {
        if (error.response.data.Error) {
          throw error.response.data.Error;
        }
        throw error;
      }
    );
  },

  CHANGE_EMAIL_DATA: async (context, payload) => {
    let { value, field } = payload;
    await HTTP.post('/settings/email', {
      [field]: value,
    }).catch((error) => {
      if (error.response.data.Error) {
        throw error.response.data.Error;
      }
      throw error;
    });
  },
};

const email_settings = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default email_settings;
