const mutations = {
  LOGIN(state, data) {
    localStorage.setItem('logged', '1');
    state.loginFailed = false;
    const { Token } = data;
    const user = JSON.stringify(data.User);
    const permissions = JSON.stringify(data.Permissions);

    localStorage.setItem('token', Token);
    localStorage.setItem('user', user);
    localStorage.setItem('permissions', permissions);
    state.token = Token;
    state.user = user;
    state.permissions = permissions;
  },
  loginWait(state, status) {
    state.loginWait = status;
  },
  loginFailed(state) {
    state.loginFailed = true;
  },
  networkError(state, status) {
    state.networkError = status;
  },
  logout(state) {
    state.loginWait = false;
    state.loginFailed = false;
    localStorage.removeItem('logged');
    state.user = null;
    state.token = null;
    state.permissions = null;
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
  },
  editUser(state, data) {
    const user = JSON.stringify(data);
    localStorage.setItem('user', user);
    state.user = user;
    state.userUpdated = true;
    setTimeout(() => {
      state.userUpdated = false;
    }, 3000);
  },
  editPassword(state, data) {
    if (data.status === 1) {
      state.userPasswordUpdated = true;
      setTimeout(() => {
        state.userPasswordUpdated = false;
      }, 3000);
    } else if (data.status === -1) {
      state.userPasswordUpdateError = true;
      state.userPasswordUpdateErrorMsg = data.msg;
      setTimeout(() => {
        state.userPasswordUpdateError = false;
      }, 3000);
    }
  },
  REFRESH_TOKEN(state, data) {
    localStorage.setItem('token', data);
    state.token = data;
  },
};
export default mutations;
