import { HTTP } from '@/utils/plugins/axios/axios';

const state = {
  user: {},
};

const getters = {
  USER: (state) => {
    return state.user;
  },
};

const mutations = {
  SET_USER: (state, payload) => {
    state.user = payload;
  },
};

const actions = {
  GET_CURRENT_USER: async ({ commit }) => {
    await HTTP.get('current_user').then(
      (response) => {
        commit('SET_USER', response.data);
      },
      (error) => {
        if (error.response.data.Error) {
          throw error.response.data.Error;
        }
        throw error;
      }
    );
  },
  CHANGE_CURRENT_USER_DATA: async (context, payload) => {
    let { value, field } = payload;
    await HTTP.post('current_user', {
      [field]: value,
    }).catch((error) => {
      if (error.response.data.Error) {
        throw error.response.data.Error;
      }
      throw error;
    });
  },
};

const profile = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default profile;
