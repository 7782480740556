var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pr-3 pl-3"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":[
        {
          text: '(###) ###-##-##',
          value: '(###) ###-##-##',
        },
        {
          text: '(###-##) #-##-##',
          value: '(###-##) #-##-##',
        },
        {
          text: 'Без маски',
          value: '################',
        } ],"label":_vm.maskLabel},model:{value:(_vm.mask),callback:function ($$v) {_vm.mask=$$v},expression:"mask"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"label":_vm.label,"rules":_vm.maskRules},on:{"input":_vm.handleInput},model:{value:(_vm.getValue),callback:function ($$v) {_vm.getValue=$$v},expression:"getValue"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }