<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-row no-gutters>
          <v-col cols="12" class="pt-0 pb-0">
            <v-card class="mr-1 mb-1">
              <v-card-title>Шаблоны договоров</v-card-title>
              <v-container fluid>
                <div>
                  <span class="font-weight-bold h3">Предварительный договор</span>
                </div>
                <div class="mt-3">
                  <v-btn href="/templates/preliminaryAgreement.docx"> Скачать </v-btn>
                  <v-btn class="ml-3" @click="uploadPreliminaryAgreement"> Загрузить </v-btn>
                  <v-btn class="ml-15" href="/templates/backup/preliminaryAgreement.docx">
                    Скачать резервную копию
                  </v-btn>
                  <v-file-input
                    truncate-length="50"
                    label="Выбрать файл для загрузки"
                    @change="handleFileUploadPreliminary($event)"
                    accept=".docx"
                    ref="preliminary"
                  ></v-file-input>
                </div>
                <v-divider class="mb-5"></v-divider>
                <div><span class="font-weight-bold h3">Договор с продавцом</span></div>
                <div class="mt-3">
                  <v-btn href="/templates/sellerAgreement.docx"> Скачать </v-btn>
                  <v-btn class="ml-3" @click="uploadSellerAgreement"> Загрузить </v-btn>
                  <v-btn class="ml-15" href="/templates/backup/sellerAgreement.docx">
                    Скачать резервную копию
                  </v-btn>
                  <v-file-input
                    truncate-length="50"
                    label="Выбрать файл для загрузки"
                    @change="handleFileUploadSeller($event)"
                    accept=".docx"
                    ref="seller"
                  ></v-file-input>
                </div>
                <v-divider class="mb-5"></v-divider>
                <div><span class="font-weight-bold h3">Договор комиссия</span></div>
                <div class="mt-3">
                  <v-btn href="/templates/commissionAgreement.docx"> Скачать </v-btn>
                  <v-btn class="ml-3" @click="uploadCommissionAgreement"> Загрузить </v-btn>
                  <v-btn class="ml-15" href="/templates/backup/commissionAgreement.docx">
                    Скачать резервную копию
                  </v-btn>
                  <v-file-input
                    truncate-length="50"
                    label="Выбрать файл для загрузки"
                    @change="handleFileUploadCommission($event)"
                    accept=".docx"
                    ref="commission"
                  ></v-file-input>
                </div>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';
export default {
  data() {
    return {
      preliminaryAgreementFile: '',
      sellerAgreementFile: '',
      commissionAgreementFile: '',
    };
  },
  methods: {
    async uploadPreliminaryAgreement() {
      if (!this.preliminaryAgreementFile) {
        this.$toastr.w('Выберите файл для загрузки');
      } else {
        let formData = new FormData();
        formData.append('file', this.preliminaryAgreementFile);
        await HTTP.post('settings/templates/preliminary_agreement', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(
          (response) => {
            this.$toastr.s('Файл успешно загружен');
            this.preliminaryAgreementFile = '';
            this.$refs.preliminary.reset();
          },
          (error) => {
            this.$toastr.e('Произошла ошибка');
            this.preliminaryAgreementFile = '';
            this.$refs.preliminary.reset();
          }
        );
      }
    },
    async uploadSellerAgreement() {
      if (!this.sellerAgreementFile) {
        this.$toastr.w('Выберите файл для загрузки');
      } else {
        let formData = new FormData();
        formData.append('file', this.sellerAgreementFile);
        await HTTP.post('settings/templates/seller_agreement', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(
          (response) => {
            this.$toastr.s('Файл успешно загружен');
            this.sellerAgreementFile = '';
            this.$refs.seller.reset();
          },
          (error) => {
            this.$toastr.e('Произошла ошибка');
            this.sellerAgreementFile = '';
            this.$refs.seller.reset();
          }
        );
      }
    },
    async uploadCommissionAgreement() {
      if (!this.commissionAgreementFile) {
        this.$toastr.w('Выберите файл для загрузки');
      } else {
        let formData = new FormData();
        formData.append('file', this.commissionAgreementFile);
        await HTTP.post('settings/templates/commission_agreement', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(
          (response) => {
            this.$toastr.s('Файл успешно загружен');
            this.commissionAgreementFile = '';
            this.$refs.commission.reset();
          },
          (error) => {
            this.$toastr.e('Произошла ошибка');
            this.commissionAgreementFile = '';
            this.$refs.commission.reset();
          }
        );
      }
    },
    handleFileUploadPreliminary(event) {
      this.preliminaryAgreementFile = event;
    },
    handleFileUploadSeller(event) {
      this.sellerAgreementFile = event;
    },
    handleFileUploadCommission(event) {
      this.commissionAgreementFile = event;
    },
    async setBreadcrumbs() {
      await this.$store.dispatch('app/SET_BREADCRUMBS', [
        { text: 'Главная', to: '/', disabled: false },
        { text: 'Настройки', to: '/settings', disabled: false },
        { text: 'Шаблоны договоров', disabled: true },
      ]);
    },
  },
  created() {
    this.setBreadcrumbs();
  },
  beforeCreate() {
    let call = async () => {
      await HTTP.get('auth/empty').then(
        () => {},
        (error) => {
          if (error.response.data.Error) {
            throw error.response.data.Error;
          }
          throw error;
        }
      );
    };
    call();
  },
};
</script>
<style lang="css">
.invisible {
  opacity: 0;
}
</style>
