var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":"","pb-10":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mr-1 mb-1"},[_c('v-tabs',[_c('v-tab',[_vm._v("Переменные")]),_c('v-tab-item',[_c('v-card-text',[_c('div',[_vm._v("Переменные обрамляються символами "),_c('b',[_vm._v("{ }")])]),_c('div',[_vm._v(" Поддерживаются условия, например: "),_c('b',[_vm._v("{#SC < 2}")]),_vm._v("Продавця"),_c('b',[_vm._v("{/}{#SC > 1}")]),_vm._v("Продавців"),_c('b',[_vm._v("{/}{#SC===\"1\"}")]),_vm._v("Текст"),_c('b',[_vm._v("{/}")])]),_c('div',[_vm._v("Поддерживаются отрицание, например: "),_c('b',[_vm._v("{#!NFH}")]),_vm._v("Текст"),_c('b',[_vm._v("{/}")])]),_c('div',[_vm._v(" Поддерживаются массивы, например: "),_c('b',[_vm._v("{#SAR}{SF}")]),_vm._v(", "),_c('b',[_vm._v("{SD} {SM} {SY}")]),_vm._v(" року народження, рнокпп "),_c('b',[_vm._v("{SR}{/SAR}")])])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchParams,"no-data-text":"Ничего не найдено","no-results-text":"Ничего не найдено","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Фильтр","clearable":""},model:{value:(_vm.searchParams),callback:function ($$v) {_vm.searchParams=$$v},expression:"searchParams"}})],1)],1)]},proxy:true},{key:"item.Symbol",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.Color}},[_vm._v(" "+_vm._s(item.Symbol)+" ")])]}},{key:"item.Field",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.Color}},[_vm._v(" "+_vm._s(item.Field)+" ")])]}}])})],1),_c('v-tab',[_vm._v("Поля")]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchFields,"no-data-text":"Ничего не найдено","no-results-text":"Ничего не найдено","headers":_vm.headersFields,"items":_vm.itemsFields,"hide-default-footer":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Фильтр","clearable":""},model:{value:(_vm.searchFields),callback:function ($$v) {_vm.searchFields=$$v},expression:"searchFields"}})],1)],1)]},proxy:true},{key:"item.Field",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.Color}},[_vm._v(" "+_vm._s(item.Field)+" ")])]}},{key:"item.Required",fn:function(ref){
var item = ref.item;
return [(item.Required === 'Обязательное поле')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.Required)+" ")]):(item.Required === 'Необязательное поле')?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.Required)+" ")]):(item.Required === 'Генерируется системой')?_c('span',{staticStyle:{"color":"gray"}},[_vm._v(" "+_vm._s(item.Required)+" ")]):_c('span',{staticStyle:{"color":"blue"}},[_vm._v(" "+_vm._s(item.Required)+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }