<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-row no-gutters>
          <v-col cols="12" class="pt-0 pb-0">
            <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
              <v-card class="mr-1 mb-3">
                <div style="width: 100%" class="text-center pt-5 mb-5">
                  <h3 class="align-self-center">Генератор договоров</h3>
                </div>
                <v-divider class="mb-0"></v-divider>
                <v-row class="mt-5 mb-5">
                  <v-col cols="12" sm="4" class="pt-0 pb-0 d-flex justify-center">
                    <span class="align-self-center"
                      ><v-icon class="mr-1" color="blue">mdi-file-document</v-icon>
                      Предварительный договор
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0 d-flex justify-center">
                    <span class="align-self-center"
                      ><v-icon class="mr-1" color="blue">mdi-file-document</v-icon>
                      Договор с продавцом
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0 d-flex justify-center">
                    <span class="align-self-center"
                      ><v-icon class="mr-1" color="blue">mdi-file-document</v-icon>
                      Договор комиссии
                    </span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-card-text>
                    <v-text-field
                      ref="exchangeRateUSD_ref"
                      v-model="exchangeRateUSD"
                      label="Курс доллара США"
                      required
                      placeholder="Введите курс"
                      :rules="exchangeRateUSDRules"
                      validate-on-blur
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-shape-rectangle-plus"
                      v-maska="'#*.####'"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          class="blue white--text"
                          rounded
                          small
                          @click="getExchangeCourseFromNBU"
                          >Взять курс с сайта НБУ
                        </v-btn>
                      </template>
                    </v-text-field>
                    <utils-datepicker-formatted
                      label="Дата договора"
                      v-model="agreementDate"
                      required
                      startDateNow
                      readonly
                      ref="agreementDateRef"
                      class="mt-3"
                    ></utils-datepicker-formatted>
                    <v-item-group mandatory v-model="sellerCountFrom0" class="mb-5">
                      <v-subheader class="ml-4">Количество продавцов</v-subheader>
                      <v-icon class="mr-1">mdi-account-multiple</v-icon>
                      <v-item
                        v-for="sellerCountFrom0 in 6"
                        :key="sellerCountFrom0"
                        v-slot="{ active, toggle }"
                      >
                        <v-chip
                          class="mr-1 mb-1 justify-center"
                          style="width: 80px"
                          active-class="green white--text"
                          :input-value="active"
                          @click="toggle"
                        >
                          {{ sellerCountFrom0 }}
                        </v-chip>
                      </v-item>
                    </v-item-group>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title v-if="sellerCount >= 2">Продавец №1</v-card-title>
                  <v-card-title v-if="sellerCount === 1">Продавец</v-card-title>
                  <v-card-text>
                    <utils-seller-block
                      sellerNumber="1"
                      @dataBlockUpdate="seller1DataBlock = $event"
                      v-if="sellerCount === 1"
                    ></utils-seller-block>
                    <utils-seller-block
                      sellerNumber="1"
                      v-if="sellerCount >= 2"
                      overrideShowSellerNumber
                      @dataBlockUpdate="seller1DataBlock = $event"
                    ></utils-seller-block>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3" v-if="sellerCount >= 2">
                <v-row>
                  <v-card-title>Продавец №2</v-card-title>
                  <v-card-text>
                    <utils-seller-block
                      sellerNumber="2"
                      @dataBlockUpdate="seller2DataBlock = $event"
                    ></utils-seller-block>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3" v-if="sellerCount >= 3">
                <v-row>
                  <v-card-title>Продавец №3</v-card-title>
                  <v-card-text>
                    <utils-seller-block
                      sellerNumber="3"
                      @dataBlockUpdate="seller3DataBlock = $event"
                    ></utils-seller-block>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3" v-if="sellerCount >= 4">
                <v-row>
                  <v-card-title>Продавец №4</v-card-title>
                  <v-card-text>
                    <utils-seller-block
                      sellerNumber="4"
                      @dataBlockUpdate="seller4DataBlock = $event"
                    ></utils-seller-block>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3" v-if="sellerCount >= 5">
                <v-row>
                  <v-card-title>Продавец №5</v-card-title>
                  <v-card-text>
                    <utils-seller-block
                      sellerNumber="5"
                      @dataBlockUpdate="seller5DataBlock = $event"
                    ></utils-seller-block>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3" v-if="sellerCount >= 6">
                <v-row>
                  <v-card-title>Продавец №6</v-card-title>
                  <v-card-text>
                    <utils-seller-block
                      sellerNumber="6"
                      @dataBlockUpdate="seller6DataBlock = $event"
                    ></utils-seller-block>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title>Покупатель</v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="buyerDataBlock.buyerFIO"
                      :counter="100"
                      label="ФИО Покупателя"
                      required
                      :rules="buyerFIORules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account"
                      maxlength="100"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <utils-datepicker-formatted
                      label="Дата рождения покупателя"
                      v-model="buyerDataBlock.buyerBirthday"
                      required
                    ></utils-datepicker-formatted>
                    <v-text-field
                      v-model="buyerDataBlock.buyerRNOKPP"
                      :counter="10"
                      label="РНОКПП Покупателя"
                      required
                      :rules="buyerRNOKPPRules"
                      autocomplete="off"
                      type="search"
                      maxlength="10"
                      prepend-icon="mdi-qrcode"
                      v-maska="'##########'"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="buyerDataBlock.buyerAddress"
                      :counter="150"
                      label="Адрес регистрации Покупателя"
                      required
                      :rules="buyerAddressRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-home-map-marker"
                      maxlength="150"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title>Объект недвижимости</v-card-title>
                  <v-card-text>
                    <v-subheader>Тип объекта недвижимости</v-subheader>
                    <v-radio-group v-model="realtyObjectSelectedModel" row mandatory class="mt-0">
                      <v-icon class="mr-2">mdi-home-variant</v-icon>
                      <v-radio label="Квартира" value="Квартира"></v-radio>
                      <v-radio label="Будинок" value="Будинок"></v-radio>
                      <v-radio label="Нежитлове приміщення" value="Нежитлове приміщення"></v-radio>
                      <v-radio label="Земельна ділянка" value="Земельна ділянка"></v-radio>
                    </v-radio-group>
                    <v-text-field
                      v-model="totalArea"
                      label="Общая площадь(метров квадратных)"
                      required
                      :rules="totalAreaRules"
                      validate-on-blur
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-shape-square-plus"
                      v-maska="'#*.##'"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-if="!NotFlatOrHouse"
                      v-model="livingArea"
                      label="Жилая площадь(метров квадратных)"
                      required
                      :rules="livingAreaRules"
                      validate-on-blur
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-shape-rectangle-plus"
                      v-maska="'#*.##'"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="realtyObjectAddress"
                      :counter="200"
                      maxlength="200"
                      label="Адрес объекта недвижимости"
                      required
                      :rules="realtyObjectAddressRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-map-marker"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="propertyTitles"
                      :counter="300"
                      maxlength="300"
                      label="Правоустанавливающие документы"
                      required
                      :rules="propertyTitlesRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-file-document"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <utils-datepicker-formatted
                      label="Срок действия договора"
                      v-model="contractTime"
                      required
                    ></utils-datepicker-formatted>
                    <utils-currency-input
                      v-model="realtyObjectCostUSD"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      required
                      label="Цена объекта в $"
                      :rules="realtyObjectCostUSDRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-currency-usd"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    >
                    </utils-currency-input>
                    <v-text-field
                      :value="realtyObjectCostUSDInWords"
                      label="Цена объекта в $ прописью"
                      readonly
                      autocomplete="off"
                      prepend-icon="mdi-currency-usd"
                    ></v-text-field>
                    <utils-currency-input
                      :value="realtyObjectCostUAH"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      label="Цена объекта в Гривне"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    >
                    </utils-currency-input>
                    <v-text-field
                      :value="realtyObjectCostUAHInWords"
                      label="Цена объекта в Гривне прописью"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    ></v-text-field>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title>Пошлины, зарегистрированные лица и сроки передачи</v-card-title>
                  <v-card-text>
                    <v-subheader>Сторона, оплачивающая госпошлину</v-subheader>
                    <v-radio-group
                      v-model="whoPayTax"
                      row
                      mandatory
                      class="mt-0"
                      ref="whoPayTax_ref"
                    >
                      <v-icon class="mr-2">mdi-home-variant</v-icon>
                      <v-radio
                        label="Продавець"
                        value="Продавець"
                        v-if="sellerCount === 1"
                      ></v-radio>
                      <v-radio label="Продавці" value="Продавці" v-if="sellerCount > 1"></v-radio>
                      <v-radio label="Покупець" value="Покупець"></v-radio>
                    </v-radio-group>
                    <v-subheader>Сторона, оплачивающая оценку</v-subheader>
                    <v-radio-group
                      v-model="whoPayAppraisal"
                      row
                      mandatory
                      class="mt-0"
                      ref="whoPayTax_ref"
                    >
                      <v-icon class="mr-2">mdi-home-variant</v-icon>
                      <v-radio
                        label="Продавець"
                        value="Продавець"
                        v-if="sellerCount === 1"
                      ></v-radio>
                      <v-radio label="Продавці" value="Продавці" v-if="sellerCount > 1"></v-radio>
                      <v-radio label="Покупець" value="Покупець"></v-radio>
                    </v-radio-group>
                    <v-text-field
                      v-if="!NotFlatOrHouse"
                      v-model="registeredPersons"
                      :counter="300"
                      maxlength="300"
                      label="Зарегистрированные лица"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-check"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <utils-datepicker-formatted
                      v-if="!NotFlatOrHouse && registeredPersons"
                      label="Сроки снятия регистрации"
                      v-model="unregisterPersonsTerm"
                      :required="unregisterPersonsTermRequired"
                    ></utils-datepicker-formatted>
                    <v-text-field
                      v-if="!NotFlatOrHouse"
                      v-model="temporaryUnregisteredPersons"
                      :counter="300"
                      maxlength="300"
                      label="Лица, временно снятые с регистрации"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-remove"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="objectTransferDeadline"
                      :counter="300"
                      maxlength="300"
                      label="Срок передачи объекта"
                      required
                      :rules="objectTransferDeadlineRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-clock-end"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title
                    >Гарантийные платежи, нотариус, адрес нотариальной сделки</v-card-title
                  >
                  <v-card-text>
                    <utils-currency-input
                      v-model="guarantyPaymentUSD"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      required
                      label="Гарантийный платеж в $"
                      :rules="guarantyPaymentUSDRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-currency-usd"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    >
                    </utils-currency-input>
                    <v-text-field
                      :value="guarantyPaymentUSDInWords"
                      label="Гарантийный платеж в $ прописью"
                      readonly
                      autocomplete="off"
                      prepend-icon="mdi-currency-usd"
                    ></v-text-field>
                    <utils-currency-input
                      :value="guarantyPaymentUAH"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      label="Гарантийный платеж в Гривне"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    >
                    </utils-currency-input>
                    <v-text-field
                      :value="guarantyPaymentUAHInWords"
                      label="Гарантийный платеж в Гривне прописью"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    ></v-text-field>
                    <v-text-field
                      v-model="notaryFIO"
                      :counter="150"
                      maxlength="150"
                      label="Ф.И.О Нотариуса"
                      autocomplete="off"
                      type="search"
                      required
                      :rules="notaryFIORules"
                      prepend-icon="mdi-account"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="notaryTransactionAddress"
                      :counter="300"
                      maxlength="300"
                      label="Адрес нотариальной сделки"
                      autocomplete="off"
                      type="search"
                      required
                      :rules="notaryTransactionAddressRules"
                      prepend-icon="mdi-map-marker-circle"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-checkbox
                      v-model="guarantyPaymentEvictionCondition"
                      label="Гарантийная сумма обеспечения выселения и сохранения состояния квартиры"
                    ></v-checkbox>

                    <utils-currency-input
                      v-if="guarantyPaymentEvictionCondition"
                      v-model="guarantyPaymentEvictionConditionUSD"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      required
                      label="Гарантийная сумма обеспечения выселения и сохранения состояния квартиры в $"
                      :rules="guarantyPaymentEvictionConditionUSDRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-currency-usd"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    >
                    </utils-currency-input>
                    <v-text-field
                      v-if="guarantyPaymentEvictionCondition"
                      :value="guarantyPaymentEvictionConditionUSDInWords"
                      label="Гарантийная сумма обеспечения выселения и сохранения состояния квартиры в $ прописью"
                      readonly
                      autocomplete="off"
                      prepend-icon="mdi-currency-usd"
                    ></v-text-field>
                    <utils-currency-input
                      v-if="guarantyPaymentEvictionCondition"
                      :value="guarantyPaymentEvictionConditionUAH"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      label="Гарантийная сумма обеспечения выселения и сохранения состояния квартиры в Гривне"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    >
                    </utils-currency-input>
                    <v-text-field
                      v-if="guarantyPaymentEvictionCondition"
                      :value="guarantyPaymentEvictionConditionUAHInWords"
                      label="Гарантийная сумма обеспечения выселения и сохранения состояния квартиры в Гривне прописью"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    ></v-text-field>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title>Договор с продавцом</v-card-title>
                  <v-card-text>
                    <v-subheader>Выбор исполнителя</v-subheader>
                    <v-radio-group
                      v-model="saExecutorSelection"
                      row
                      mandatory
                      class="mt-0"
                      @change="fillExecutorSA"
                    >
                      <v-icon class="mr-2">mdi-account-circle</v-icon>
                      <v-radio :label="saExecutor1" :value="1"></v-radio>
                      <v-radio :label="saExecutor2" :value="2"></v-radio>
                      <v-radio :label="saExecutor3" :value="3"></v-radio>
                    </v-radio-group>
                    <v-text-field
                      v-model="saExecutor"
                      :counter="400"
                      maxlength="400"
                      label="Исполнитель"
                      required
                      :rules="saExecutorRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-circle"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="saExecutorDetailsForSignature"
                      :counter="400"
                      maxlength="400"
                      label="Исполнитель, детально"
                      required
                      :rules="saExecutorDetailsForSignatureRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-settings"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="saExecutorSignatory"
                      :counter="400"
                      maxlength="400"
                      label="Исполнитель, подписант"
                      required
                      :rules="saExecutorSignatoryRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-settings"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="docsForStorage"
                      :counter="300"
                      maxlength="300"
                      label="Документы на хранение"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-file-document"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="storageBoxNumber"
                      :counter="100"
                      maxlength="100"
                      required
                      :rules="storageBoxNumberRules"
                      label="Номер бокса на хранение денег"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-pound-box"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-card class="mr-1 mb-3">
                <v-row>
                  <v-card-title>Договор Комиссия</v-card-title>
                  <v-card-text>
                    <v-subheader>Выбор исполнителя</v-subheader>
                    <v-radio-group
                      v-model="executorSelection"
                      row
                      mandatory
                      class="mt-0"
                      @change="fillExecutor"
                    >
                      <v-icon class="mr-2">mdi-account-circle</v-icon>
                      <v-radio :label="executor1" :value="1"></v-radio>
                      <v-radio :label="executor2" :value="2"></v-radio>
                      <v-radio :label="executor3" :value="3"></v-radio>
                    </v-radio-group>
                    <v-text-field
                      v-model="executor"
                      :counter="400"
                      maxlength="400"
                      label="Исполнитель"
                      required
                      :rules="executorRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-circle"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="executorDetailsForSignature"
                      :counter="400"
                      maxlength="400"
                      label="Исполнитель, детально"
                      required
                      :rules="executorDetailsForSignatureRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-settings"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-text-field
                      v-model="executorSignatory"
                      :counter="400"
                      maxlength="400"
                      label="Исполнитель, подписант"
                      required
                      :rules="executorSignatoryRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-account-settings"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    ></v-text-field>
                    <v-subheader
                      >Выберите стророну, оплачивающую комиссию (в случае если выбран продавец -
                      будут использованы данные продавца №1)</v-subheader
                    >
                    <v-radio-group
                      v-model="whoIsSecondSideCommissionAgreement"
                      row
                      mandatory
                      class="mt-0"
                      ref="whoIsSecondSideCommissionAgreement_ref"
                    >
                      <v-icon class="mr-2">mdi-home-variant</v-icon>
                      <v-radio label="Покупець" value="Покупець"></v-radio>
                      <v-radio label="Продавець" value="Продавець"></v-radio>
                    </v-radio-group>
                    <utils-currency-input
                      v-model="agencyCommissionUSD"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      required
                      label="Комиссия агенства в $"
                      :rules="guarantyPaymentUSDRules"
                      autocomplete="off"
                      type="search"
                      prepend-icon="mdi-currency-usd"
                      @keydown.enter="focusNextInput($event)"
                      @keydown.tab="focusNextInput($event)"
                    >
                    </utils-currency-input>
                    <v-text-field
                      :value="agencyCommissionUSDInWords"
                      label="Комиссия агенства в $ прописью"
                      readonly
                      autocomplete="off"
                      prepend-icon="mdi-currency-usd"
                    ></v-text-field>
                    <utils-currency-input
                      :value="agencyCommissionUAH"
                      :options="{
                        currency: null,
                        locale: 'ru-RU',
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        allowNegative: false,
                      }"
                      label="Комиссия агенства в Гривне"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    >
                    </utils-currency-input>
                    <v-text-field
                      :value="agencyCommissionUAHInWords"
                      label="Комиссия агенства в Гривне прописью"
                      readonly
                      prepend-icon="mdi-currency-usd-off"
                    ></v-text-field>
                    <utils-datepicker-formatted
                      label="Дата начала предоставления услуг"
                      v-model="serviceStartDate"
                      required
                    ></utils-datepicker-formatted>
                    <utils-datepicker-formatted
                      label="Дата окончания предоставления услуг"
                      v-model="serviceEndDate"
                      required
                    ></utils-datepicker-formatted>
                    <utils-datepicker-formatted
                      label="Окончательная дата оплаты услуг"
                      v-model="finalPaymentDate"
                      required
                    ></utils-datepicker-formatted>
                  </v-card-text>
                </v-row>
              </v-card>
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                Сгенерировать договора
              </v-btn>
              <v-btn color="error" class="mr-4" @click="reset">
                Удалить все заполненные данные
              </v-btn>
              <template v-if="!isProduction">
                <v-btn color="" @click="test"> TEST BUTTON </v-btn>
                <v-btn color="" @click="testDataFill"> TEST2 BUTTON </v-btn>
              </template>
            </v-form>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { HTTPExternal } from '@/utils/plugins/axios/axios';
import { HTTP } from '@/utils/plugins/axios/axios';
import moment from 'moment';
import 'moment/locale/uk';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import { originUrl, productionMarker } from '@/config/api';

let expressions = require('angular-expressions');
let assign = require('lodash/assign');
expressions.filters.lower = function (input) {
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  if (tag === '.') {
    return {
      get: function (s) {
        return s;
      },
    };
  }
  const expr = expressions.compile(tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"'));
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}
export default {
  data() {
    return {
      numberToString: require('number-to-cyrillic'),
      isProduction: false,
      preliminaryAgreement: true,
      sellerAgreement: true,
      commissionAgreement: true,
      exchangeRateUSD: null,
      exchangeRateUSDRules: [
        (v) => !!v || 'Курс Доллара США - обязательное поле!',
        (v) => {
          const pattern = /^[0-9]+([.,][0-9]{1,4})?$/;
          return pattern.test(v) || 'Неправильный формат.';
        },
      ],
      agreementDate: null,
      sellerCountFrom0: 0,
      valid: true,
      seller1DataBlock: {},
      seller2DataBlock: {},
      seller3DataBlock: {},
      seller4DataBlock: {},
      seller5DataBlock: {},
      seller6DataBlock: {},
      buyerDataBlock: {
        buyerFIO: '',
        buyerBirthday: '',
        buyerRNOKPP: '',
        buyerAddress: '',
        d: '',
        m: '',
        y: '',
      },
      buyerFIORules: [(v) => !!v || 'ФИО Покупателя - обязательное поле!'],
      buyerRNOKPPRules: [
        (v) => !!v || 'РНОКПП Покупателя - обязательное поле!',
        (v) => (v && v.length === 10) || 'Не менее 10 цифр',
      ],
      buyerAddressRules: [(v) => !!v || 'Адрес регистрации Покупателя - обязательное поле!'],
      realtyObjectSelectedModel: null,
      realtyObjectSelectedRules: [(v) => !!v || '  Выберите Тип объекта недвижимости!'],
      totalArea: null,
      totalAreaRules: [
        (v) => !!v || 'Общая площадь - обязательное поле!',
        (v) => {
          const pattern = /^[0-9]+([.,][0-9]{1,2})?$/;
          return pattern.test(v) || 'Неправильный формат.';
        },
      ],
      livingArea: null,
      NotFlatOrHouse: false,
      livingAreaRules: [
        (v) => !!v || 'Жилая площадь - обязательное поле!',
        (v) => {
          const pattern = /^[0-9]+([.,][0-9]{1,2})?$/;
          return pattern.test(v) || 'Неправильный формат.';
        },
        (v) => {
          let totalINT = Math.fround(parseFloat(this.totalArea));
          let livingINT = Math.fround(parseFloat(v));
          return !(totalINT < livingINT) || 'Жилая площадь не может быть больше чем общая!';
        },
      ],
      realtyObjectAddress: '',
      realtyObjectAddressRules: [(v) => !!v || 'Адрес объекта недвижимости - обязательное поле!'],
      propertyTitles: '',
      propertyTitlesRules: [(v) => !!v || 'Правоустанавливающие документы - обязательное поле!'],
      contractTime: null,
      realtyObjectCostUSD: null,
      realtyObjectCostUSDRules: [(v) => !!v || 'Цена объекта в $ - обязательное поле!'],
      whoPayTax: null,
      whoPayAppraisal: null,
      registeredPersons: '',
      unregisterPersonsTerm: null,
      unregisterPersonsTermRequired: false,
      temporaryUnregisteredPersons: '',
      objectTransferDeadline: 'в день укладання угоди купівлі-продажу',
      objectTransferDeadlineRules: [(v) => !!v || 'Срок передачи объекта - обязательное поле!'],
      guarantyPaymentUSD: null,
      guarantyPaymentUSDRules: [(v) => !!v || 'Комиссия агенства в $ - обязательное поле!'],
      notaryFIO: '',
      notaryFIORules: [(v) => !!v || 'Ф.И.О Нотариуса - обязательное поле!'],
      notaryTransactionAddress: '',
      notaryTransactionAddressRules: [
        (v) => !!v || 'Адрес нотариальной сделки - обязательное поле!',
      ],
      docsForStorage: '',
      storageBoxNumber: null,
      storageBoxNumberRules: [(v) => !!v || 'Номер бокса на хранение денег - обязательное поле!'],
      saExecutorSelection: 1,
      saExecutor: null,
      saExecutorRules: [(v) => !!v || 'Исполнитель - обязательное поле!'],
      saExecutorDetailsForSignature: null,
      saExecutorDetailsForSignatureRules: [
        (v) => !!v || 'Исполнитель, детально - обязательное поле!',
      ],
      saExecutorSignatory: null,
      saExecutorSignatoryRules: [(v) => !!v || 'Исполнитель, подписант - обязательное поле!'],
      saExecutor1: null,
      saExecutor1DetailsForSignature: null,
      saExecutor1Signatory: null,
      saExecutor2: null,
      saExecutor2DetailsForSignature: null,
      saExecutor2Signatory: null,
      saExecutor3: null,
      saExecutor3DetailsForSignature: null,
      saExecutor3Signatory: null,
      agencyCommissionUSD: null,
      executorSelection: 1,
      executor1: null,
      executor1DetailsForSignature: null,
      executor1Signatory: null,
      executor2: null,
      executor2DetailsForSignature: null,
      executor2Signatory: null,
      executor3: null,
      executor3DetailsForSignature: null,
      executor3Signatory: null,
      executor: null,
      executorRules: [(v) => !!v || 'Исполнитель - обязательное поле!'],
      serviceStartDate: null,
      serviceEndDate: null,
      finalPaymentDate: null,
      executorDetailsForSignature: null,
      executorDetailsForSignatureRules: [
        (v) => !!v || 'Исполнитель, детально - обязательное поле!',
      ],
      executorSignatory: null,
      executorSignatoryRules: [(v) => !!v || 'Исполнитель, подписант - обязательное поле!'],
      guarantyPaymentEvictionCondition: false,
      guarantyPaymentEvictionConditionUSD: null,
      guarantyPaymentEvictionConditionUSDRules: [
        (v) =>
          !!v ||
          'Гарантийная сумма обеспечения выселения и сохранения состояния квартиры в $ - обязательное поле!',
      ],
      whoIsSecondSideCommissionAgreement: null,
    };
  },
  watch: {
    registeredPersons(val) {
      this.unregisterPersonsTermRequired = val !== '' && val !== null && val !== undefined;
    },
    sellerCount(val) {
      switch (val) {
        case 1:
          this.seller2DataBlock = {};
          this.seller3DataBlock = {};
          this.seller4DataBlock = {};
          this.seller5DataBlock = {};
          this.seller6DataBlock = {};
          this.whoPayTaxSellerCountChange(1);
          this.whoPayAppraisalSellerCountChange(1);
          break;
        case 2:
          this.seller3DataBlock = {};
          this.seller4DataBlock = {};
          this.seller5DataBlock = {};
          this.seller6DataBlock = {};
          this.whoPayTaxSellerCountChange(2);
          this.whoPayAppraisalSellerCountChange(2);
          break;
        case 3:
          this.seller4DataBlock = {};
          this.seller5DataBlock = {};
          this.seller6DataBlock = {};
          this.whoPayTaxSellerCountChange(3);
          this.whoPayAppraisalSellerCountChange(3);
          break;
        case 4:
          this.seller5DataBlock = {};
          this.seller6DataBlock = {};
          this.whoPayTaxSellerCountChange(4);
          this.whoPayAppraisalSellerCountChange(4);
          break;
        case 5:
          this.seller6DataBlock = {};
          this.whoPayTaxSellerCountChange(5);
          this.whoPayAppraisalSellerCountChange(5);
          break;
        case 6:
          this.whoPayTaxSellerCountChange(6);
          this.whoPayAppraisalSellerCountChange(6);
          break;
      }
    },
    realtyObjectSelectedModel(val) {
      if (val === 'Нежитлове приміщення' || val === 'Земельна ділянка') {
        this.NotFlatOrHouse = true;
        this.livingArea = null;
        this.registeredPersons = null;
        this.unregisterPersonsTerm = null;
        this.temporaryUnregisteredPersons = null;
      } else {
        this.NotFlatOrHouse = false;
      }
    },
  },
  computed: {
    sellerCount() {
      return this.sellerCountFrom0 + 1;
    },
    realtyObjectCostUSDInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.realtyObjectCostUSD) {
        words = numberToString.convert(this.realtyObjectCostUSD.toFixed(2), {
          currency: 'usd',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    realtyObjectCostUAH() {
      if (this.exchangeRateUSD && this.realtyObjectCostUSD) {
        return this.exchangeRateUSD * this.realtyObjectCostUSD;
      }
      return null;
    },
    realtyObjectCostUAHInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.realtyObjectCostUAH) {
        words = numberToString.convert(this.realtyObjectCostUAH.toFixed(2), {
          currency: 'uah',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    guarantyPaymentUSDInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.guarantyPaymentUSD) {
        words = numberToString.convert(this.guarantyPaymentUSD.toFixed(2), {
          currency: 'usd',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    guarantyPaymentUAH() {
      if (this.exchangeRateUSD && this.guarantyPaymentUSD) {
        return this.exchangeRateUSD * this.guarantyPaymentUSD;
      }
      return null;
    },
    guarantyPaymentUAHInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.guarantyPaymentUAH) {
        words = numberToString.convert(this.guarantyPaymentUAH.toFixed(2), {
          currency: 'uah',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    agencyCommissionUSDInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.agencyCommissionUSD) {
        words = numberToString.convert(this.agencyCommissionUSD.toFixed(2), {
          currency: 'usd',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    agencyCommissionUAH() {
      if (this.exchangeRateUSD && this.agencyCommissionUSD) {
        return this.exchangeRateUSD * this.agencyCommissionUSD;
      }
      return null;
    },
    agencyCommissionUAHInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.agencyCommissionUAH) {
        words = numberToString.convert(this.agencyCommissionUAH.toFixed(2), {
          currency: 'uah',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    guarantyPaymentEvictionConditionUSDInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.guarantyPaymentEvictionConditionUSD) {
        words = numberToString.convert(this.guarantyPaymentEvictionConditionUSD.toFixed(2), {
          currency: 'usd',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
    guarantyPaymentEvictionConditionUAH() {
      if (this.exchangeRateUSD && this.guarantyPaymentEvictionConditionUSD) {
        return this.guarantyPaymentEvictionConditionUSD * this.guarantyPaymentEvictionConditionUSD;
      }
      return null;
    },
    guarantyPaymentEvictionConditionUAHInWords() {
      let numberToString = require('number-to-cyrillic');
      let words;
      if (this.guarantyPaymentEvictionConditionUAH) {
        words = numberToString.convert(this.guarantyPaymentEvictionConditionUAH.toFixed(2), {
          currency: 'uah',
          capitalize: true,
        });
        if (words.fractional) {
          return (
            words.convertedInteger +
            ' ' +
            words.integerCurrency +
            ' та ' +
            words.convertedFractional +
            ' ' +
            words.fractionalCurrency
          );
        } else {
          return words.convertedInteger + ' ' + words.integerCurrency;
        }
      }
      return null;
    },
  },
  // beforeCreate() {
  //   let call = async () => {
  //     await HTTP.get('auth/empty').then(
  //       () => {},
  //       (error) => {
  //         if (error.response.data.Error) {
  //           throw error.response.data.Error;
  //         }
  //         throw error;
  //       }
  //     );
  //   };
  //   call();
  // },
  async created() {
    this.isProduction = productionMarker();
    await this.$store.dispatch('profile/GET_CURRENT_USER').then(
      () => {
        this.executor = this.$store.getters['profile/USER'].Executor1;
        this.executorDetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor1DetailsForSignature;
        this.executorSignatory = this.$store.getters['profile/USER'].Executor1Signatory;

        this.executor1 = this.$store.getters['profile/USER'].Executor1;
        this.executor1DetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor1DetailsForSignature;
        this.executor1Signatory = this.$store.getters['profile/USER'].Executor1Signatory;

        this.executor2 = this.$store.getters['profile/USER'].Executor2;
        this.executor2DetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor2DetailsForSignature;
        this.executor2Signatory = this.$store.getters['profile/USER'].Executor2Signatory;

        this.executor3 = this.$store.getters['profile/USER'].Executor3;
        this.executor3DetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor3DetailsForSignature;
        this.executor3Signatory = this.$store.getters['profile/USER'].Executor3Signatory;

        this.saExecutor = this.$store.getters['profile/USER'].Executor1;
        this.saExecutorDetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor1DetailsForSignature;
        this.saExecutorSignatory = this.$store.getters['profile/USER'].Executor1Signatory;

        this.saExecutor1 = this.$store.getters['profile/USER'].Executor1;
        this.saExecutor1DetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor1DetailsForSignature;
        this.saExecutor1Signatory = this.$store.getters['profile/USER'].Executor1Signatory;

        this.saExecutor2 = this.$store.getters['profile/USER'].Executor2;
        this.saExecutor2DetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor2DetailsForSignature;
        this.saExecutor2Signatory = this.$store.getters['profile/USER'].Executor2Signatory;

        this.saExecutor3 = this.$store.getters['profile/USER'].Executor3;
        this.saExecutor3DetailsForSignature = this.$store.getters[
          'profile/USER'
        ].Executor3DetailsForSignature;
        this.saExecutor3Signatory = this.$store.getters['profile/USER'].Executor3Signatory;
      },
      (error) => {
        if (error === '404') {
          this.$toastr.e('Текущий пользователь не существует или был удален', 'Произошла ошибка');
        } else {
          this.$toastr.e('Неизвестная ошибка');
        }
        this.$store.dispatch('auth/LOGOUT');
      }
    );
  },
  methods: {
    fillExecutor() {
      switch (this.executorSelection) {
        case 1:
          this.executor = this.executor1;
          this.executorDetailsForSignature = this.executor1DetailsForSignature;
          this.executorSignatory = this.executor1Signatory;
          break;
        case 2:
          this.executor = this.executor2;
          this.executorDetailsForSignature = this.executor2DetailsForSignature;
          this.executorSignatory = this.executor2Signatory;
          break;
        case 3:
          this.executor = this.executor3;
          this.executorDetailsForSignature = this.executor3DetailsForSignature;
          this.executorSignatory = this.executor3Signatory;
          break;
      }
    },
    fillExecutorSA() {
      switch (this.saExecutorSelection) {
        case 1:
          this.saExecutor = this.saExecutor1;
          this.saExecutorDetailsForSignature = this.saExecutor1DetailsForSignature;
          this.saExecutorSignatory = this.saExecutor1Signatory;
          break;
        case 2:
          this.saExecutor = this.saExecutor2;
          this.saExecutorDetailsForSignature = this.saExecutor2DetailsForSignature;
          this.saExecutorSignatory = this.saExecutor2Signatory;
          break;
        case 3:
          this.saExecutor = this.saExecutor3;
          this.saExecutorDetailsForSignature = this.saExecutor3DetailsForSignature;
          this.saExecutorSignatory = this.saExecutor3Signatory;
          break;
      }
    },
    async getExchangeCourseFromNBU() {
      let today = moment().format('YYYYMMDD');
      await HTTPExternal.get(
        'https://bank.gov.ua/NBUStatService/v1/statdirectory/exchangenew?json&valcode=USD&date=' +
          today
      ).then(
        (response) => {
          this.exchangeRateUSD = response.data[0].rate;
          this.$toastr.s(
            'Текущий курс Доллара успешно получен. Дата курса ' + response.data[0].exchangedate
          );
          this.$refs.exchangeRateUSD_ref.resetValidation();
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          }
        }
      );
    },
    focusNextInput(e) {
      e.preventDefault();
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="search"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length - 1) {
        if (inputs[index + 1].name === 'datepicker') {
          inputs[index + 1].focus();
          inputs[index + 1].click();
        } else {
          inputs[index + 1].focus();
        }
      } else {
        inputs[index].blur();
      }
    },
    whoPayTaxSellerCountChange(sellerCount) {
      if (sellerCount === 1) {
        if (this.whoPayTax === 'Продавці') {
          this.whoPayTax = 'Продавець';
        }
      }
      if (sellerCount > 1) {
        if (this.whoPayTax === 'Продавець') {
          this.whoPayTax = 'Продавці';
        }
      }
    },
    whoPayAppraisalSellerCountChange(sellerCount) {
      if (sellerCount === 1) {
        if (this.whoPayAppraisal === 'Продавці') {
          this.whoPayAppraisal = 'Продавець';
        }
      }
      if (sellerCount > 1) {
        if (this.whoPayAppraisal === 'Продавець') {
          this.whoPayAppraisal = 'Продавці';
        }
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.generateAndDownloadDocs();
      } else {
        this.$toastr.e('Заполнены не все обязательные поля!');
      }
    },
    test() {
      this.generateAndDownloadDocs();
    },
    testDataFill() {
      this.agreementDate = '06.03.2021';
      this.exchangeRateUSD = '27.7564';
      this.seller1DataBlock.sellerFIO = 'Владимиров Мстислав Денисович';
      this.seller1DataBlock.sellerBirthday = '22.06.1986';
      this.seller1DataBlock.d = '22';
      this.seller1DataBlock.m = 'червня';
      this.seller1DataBlock.y = '1986';
      this.seller1DataBlock.sellerRNOKPP = '3787822384';
      this.seller1DataBlock.sellerAddress = 'г.Киев, Смелянская Ул., дом 8, кв. 23';

      this.seller2DataBlock.sellerFIO = 'Кудрявцев Егор Миронович';
      this.seller2DataBlock.sellerBirthday = '12.05.1993';
      this.seller2DataBlock.d = '22';
      this.seller2DataBlock.m = 'червня';
      this.seller2DataBlock.y = '1986';
      this.seller2DataBlock.sellerRNOKPP = '3774747422';
      this.seller2DataBlock.sellerAddress = 'г.Одесса, Большая Арнаутская Ул., дом 7, кв. 7';

      this.seller3DataBlock.sellerFIO = 'Соловьёв Давид Ростиславович';
      this.seller3DataBlock.sellerBirthday = '16.01.1964';
      this.seller3DataBlock.d = '22';
      this.seller3DataBlock.m = 'червня';
      this.seller3DataBlock.y = '1986';
      this.seller3DataBlock.sellerRNOKPP = '3845672352';
      this.seller3DataBlock.sellerAddress = 'г.Киев, Цветочная Ул., дом 26, кв. 94';

      this.seller4DataBlock.sellerFIO = 'Стрелков Карл Олегович';
      this.seller4DataBlock.sellerBirthday = '01.04.1990';
      this.seller4DataBlock.d = '22';
      this.seller4DataBlock.m = 'червня';
      this.seller4DataBlock.y = '1986';
      this.seller4DataBlock.sellerRNOKPP = '4929582344';
      this.seller4DataBlock.sellerAddress = 'г.Киев, Гладкова Ул., дом 12, кв. 95';

      this.seller5DataBlock.sellerFIO = 'Шаров Никифор Владимирович';
      this.seller5DataBlock.sellerBirthday = '25.10.1973';
      this.seller5DataBlock.d = '22';
      this.seller5DataBlock.m = 'червня';
      this.seller5DataBlock.y = '1986';
      this.seller5DataBlock.sellerRNOKPP = '5477177711';
      this.seller5DataBlock.sellerAddress = 'г.Киев, Успенського Г. Пров., дом 32, кв. 1';

      this.seller6DataBlock.sellerFIO = 'Одинцов Оскар Константинович';
      this.seller6DataBlock.sellerBirthday = '12.12.1973';
      this.seller6DataBlock.d = '22';
      this.seller6DataBlock.m = 'червня';
      this.seller6DataBlock.y = '1986';
      this.seller6DataBlock.sellerRNOKPP = '4887611140';
      this.seller6DataBlock.sellerAddress = 'г.Киев, Селецька Вул., дом 21, кв. 47';

      this.buyerDataBlock.buyerFIO = 'Шашков Корнелий Созонович';
      this.buyerDataBlock.buyerBirthday = '28.10.1983';
      this.seller2DataBlock.d = '22';
      this.seller2DataBlock.m = 'червня';
      this.seller2DataBlock.y = '1986';
      this.buyerDataBlock.buyerRNOKPP = '3456584087';
      this.buyerDataBlock.buyerAddress = 'г.Киев, Тираспольская Ул., дом 4, кв. 9';

      this.sellerCountFrom0 = 5;

      this.totalArea = '220.5';
      this.livingArea = '180';
      this.realtyObjectAddress = 'г. Киев, Маршака Ул., дом 77 кв.5';
      this.propertyTitles =
        'Свидетельство про регистрацию собственности, выданное ччем попало много текста';
      this.contractTime = '07.03.2021';

      this.registeredPersons = 'Ильин Давид Дамирович, Елисеев Касьян Владленович';
      this.unregisterPersonsTerm = '05.03.2021';
      this.temporaryUnregisteredPersons = 'Киселёв Валентин Юлианович';

      this.notaryFIO = 'приватний нотаріус Ковалёв Ермак Парфеньевич';
      this.notaryTransactionAddress = 'г. Одесса, Артиллерийская Ул., дом 1/1, кв. 20';

      this.docsForStorage = 'Свидетельство о регистрации тектса много';
      this.storageBoxNumber = '1555';

      this.executor = 'Сергеев Вадим Натанович';
      this.serviceStartDate = '04.03.2021';
      this.serviceEndDate = '06.03.2021';
      this.finalPaymentDate = '06.03.2021';
      this.executorDetailsForSignature =
        'очень много текста,очень много текста,очень много текста,очень много текста,очень много текста,очень много текста,очень много текста,';

      this.agencyCommissionUSD = 1000;
      this.realtyObjectCostUSD = 50000;
      this.guarantyPaymentUSD = 500;
    },
    numberWithSpaces(x) {
      let parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    },
    dateToWords(stringDate) {
      let d = moment(stringDate, 'DD.MM.YYYY');
      let day = d.format('DD');
      let month = d.locale('uk').format('D MMMM');
      month = month.replace(/[0-9 ]/g, '');
      let year = d.format('YYYY');
      return { day, month, year };
    },
    formRequestObject() {
      let sellers = [];
      let oneSeller = {
        SF: '',
        SB: '',
        SR: '',
        SA: '',
        SD: '',
        SM: '',
        SY: '',
      };
      oneSeller.SF = this.seller1DataBlock.sellerFIO;
      oneSeller.SB = this.seller1DataBlock.sellerBirthday;
      oneSeller.SR = this.seller1DataBlock.sellerRNOKPP;
      oneSeller.SA = this.seller1DataBlock.sellerAddress;
      oneSeller.SD = this.seller1DataBlock.d;
      oneSeller.SM = this.seller1DataBlock.m;
      oneSeller.SY = this.seller1DataBlock.y;

      sellers.push(oneSeller);
      oneSeller = {};
      if (Object.keys(this.seller2DataBlock).length > 0) {
        oneSeller.SF = this.seller2DataBlock.sellerFIO;
        oneSeller.SB = this.seller2DataBlock.sellerBirthday;
        oneSeller.SR = this.seller2DataBlock.sellerRNOKPP;
        oneSeller.SA = this.seller2DataBlock.sellerAddress;
        oneSeller.SD = this.seller2DataBlock.d;
        oneSeller.SM = this.seller2DataBlock.m;
        oneSeller.SY = this.seller2DataBlock.y;

        sellers.push(oneSeller);
        oneSeller = {};
      }
      if (Object.keys(this.seller3DataBlock).length > 0) {
        oneSeller.SF = this.seller3DataBlock.sellerFIO;
        oneSeller.SB = this.seller3DataBlock.sellerBirthday;
        oneSeller.SR = this.seller3DataBlock.sellerRNOKPP;
        oneSeller.SA = this.seller3DataBlock.sellerAddress;
        oneSeller.SD = this.seller3DataBlock.d;
        oneSeller.SM = this.seller3DataBlock.m;
        oneSeller.SY = this.seller3DataBlock.y;

        sellers.push(oneSeller);
        oneSeller = {};
      }
      if (Object.keys(this.seller4DataBlock).length > 0) {
        oneSeller.SF = this.seller4DataBlock.sellerFIO;
        oneSeller.SB = this.seller4DataBlock.sellerBirthday;
        oneSeller.SR = this.seller4DataBlock.sellerRNOKPP;
        oneSeller.SA = this.seller4DataBlock.sellerAddress;
        oneSeller.SD = this.seller4DataBlock.d;
        oneSeller.SM = this.seller4DataBlock.m;
        oneSeller.SY = this.seller4DataBlock.y;

        sellers.push(oneSeller);
        oneSeller = {};
      }
      if (Object.keys(this.seller5DataBlock).length > 0) {
        oneSeller.SF = this.seller5DataBlock.sellerFIO;
        oneSeller.SB = this.seller5DataBlock.sellerBirthday;
        oneSeller.SR = this.seller5DataBlock.sellerRNOKPP;
        oneSeller.SA = this.seller5DataBlock.sellerAddress;
        oneSeller.SD = this.seller5DataBlock.d;
        oneSeller.SM = this.seller5DataBlock.m;
        oneSeller.SY = this.seller5DataBlock.y;

        sellers.push(oneSeller);
        oneSeller = {};
      }
      if (Object.keys(this.seller6DataBlock).length > 0) {
        oneSeller.SF = this.seller6DataBlock.sellerFIO;
        oneSeller.SB = this.seller6DataBlock.sellerBirthday;
        oneSeller.SR = this.seller6DataBlock.sellerRNOKPP;
        oneSeller.SA = this.seller6DataBlock.sellerAddress;
        oneSeller.SD = this.seller6DataBlock.d;
        oneSeller.SM = this.seller6DataBlock.m;
        oneSeller.SY = this.seller6DataBlock.y;

        sellers.push(oneSeller);
        oneSeller = {};
      }
      let dataObject = {};
      Object.assign(
        dataObject,
        { AC: this.exchangeRateUSD },
        { A1: this.agreementDate },
        { A1D: this.dateToWords(this.agreementDate).day },
        { A1M: this.dateToWords(this.agreementDate).month },
        { A1Y: this.dateToWords(this.agreementDate).year },
        { SC: this.sellerCount },
        { SAR: sellers },
        { S1F: this.seller1DataBlock.sellerFIO },
        { S1B: this.seller1DataBlock.sellerBirthday },
        { S1D: this.dateToWords(this.seller1DataBlock.sellerBirthday).day },
        { S1M: this.dateToWords(this.seller1DataBlock.sellerBirthday).month },
        { S1Y: this.dateToWords(this.seller1DataBlock.sellerBirthday).year },
        { S1R: this.seller1DataBlock.sellerRNOKPP },
        { S1A: this.seller1DataBlock.sellerAddress },
        { S2F: this.seller2DataBlock.sellerFIO },
        { S2B: this.seller2DataBlock.sellerBirthday },
        { S2D: this.dateToWords(this.seller2DataBlock.sellerBirthday).day },
        { S2M: this.dateToWords(this.seller2DataBlock.sellerBirthday).month },
        { S2Y: this.dateToWords(this.seller2DataBlock.sellerBirthday).year },
        { S2R: this.seller2DataBlock.sellerRNOKPP },
        { S2A: this.seller2DataBlock.sellerAddress },
        { S3F: this.seller3DataBlock.sellerFIO },
        { S3B: this.seller3DataBlock.sellerBirthday },
        { S3D: this.dateToWords(this.seller3DataBlock.sellerBirthday).day },
        { S3M: this.dateToWords(this.seller3DataBlock.sellerBirthday).month },
        { S3Y: this.dateToWords(this.seller3DataBlock.sellerBirthday).year },
        { S3R: this.seller3DataBlock.sellerRNOKPP },
        { S3A: this.seller3DataBlock.sellerAddress },
        { S4F: this.seller4DataBlock.sellerFIO },
        { S4B: this.seller4DataBlock.sellerBirthday },
        { S4D: this.dateToWords(this.seller4DataBlock.sellerBirthday).day },
        { S4M: this.dateToWords(this.seller4DataBlock.sellerBirthday).month },
        { S4Y: this.dateToWords(this.seller4DataBlock.sellerBirthday).year },
        { S4R: this.seller4DataBlock.sellerRNOKPP },
        { S4A: this.seller4DataBlock.sellerAddress },
        { S5F: this.seller5DataBlock.sellerFIO },
        { S5B: this.seller5DataBlock.sellerBirthday },
        { S5D: this.dateToWords(this.seller5DataBlock.sellerBirthday).day },
        { S5M: this.dateToWords(this.seller5DataBlock.sellerBirthday).month },
        { S5Y: this.dateToWords(this.seller5DataBlock.sellerBirthday).year },
        { S5R: this.seller5DataBlock.sellerRNOKPP },
        { S5A: this.seller5DataBlock.sellerAddress },
        { S6F: this.seller6DataBlock.sellerFIO },
        { S6B: this.seller6DataBlock.sellerBirthday },
        { S6D: this.dateToWords(this.seller6DataBlock.sellerBirthday).day },
        { S6M: this.dateToWords(this.seller6DataBlock.sellerBirthday).month },
        { S6Y: this.dateToWords(this.seller6DataBlock.sellerBirthday).year },
        { S6R: this.seller6DataBlock.sellerRNOKPP },
        { S6A: this.seller6DataBlock.sellerAddress },
        { B1F: this.buyerDataBlock.buyerFIO },
        { B1B: this.buyerDataBlock.buyerBirthday },
        { B1D: this.dateToWords(this.buyerDataBlock.buyerBirthday).day },
        { B1M: this.dateToWords(this.buyerDataBlock.buyerBirthday).month },
        { B1Y: this.dateToWords(this.buyerDataBlock.buyerBirthday).year },
        { B1R: this.buyerDataBlock.buyerRNOKPP },
        { B1A: this.buyerDataBlock.buyerAddress },
        { NFH: this.NotFlatOrHouse },
        { N1: this.realtyObjectSelectedModel },
        { N2: this.totalArea },
        { N3: this.livingArea },
        { N4: this.realtyObjectAddress },
        { N5: this.propertyTitles },
        { N6: this.contractTime },
        { N6D: this.dateToWords(this.contractTime).day },
        { N6M: this.dateToWords(this.contractTime).month },
        { N6Y: this.dateToWords(this.contractTime).year },
        { N7: this.numberWithSpaces(this.realtyObjectCostUSD.toFixed(2)) },
        { N8: this.realtyObjectCostUSDInWords },
        { N9: this.numberWithSpaces(this.realtyObjectCostUAH.toFixed(2)) },
        { N10: this.realtyObjectCostUAHInWords },
        { T1: this.whoPayTax },
        { T2: this.whoPayAppraisal },
        { T3: this.registeredPersons },
        { T4: this.unregisterPersonsTerm },
        { T4D: this.dateToWords(this.unregisterPersonsTerm).day },
        { T4M: this.dateToWords(this.unregisterPersonsTerm).month },
        { T4Y: this.dateToWords(this.unregisterPersonsTerm).year },
        { T5: this.temporaryUnregisteredPersons },
        { T6: this.objectTransferDeadline },
        { G1: this.numberWithSpaces(this.guarantyPaymentUSD.toFixed(2)) },
        { G2: this.guarantyPaymentUSDInWords },
        { G3: this.numberWithSpaces(this.guarantyPaymentUAH.toFixed(2)) },
        { G4: this.guarantyPaymentUAHInWords },
        { G5: this.notaryFIO },
        { G6: this.notaryTransactionAddress },
        { G7: this.guarantyPaymentEvictionCondition },
        { G8: this.guarantyPaymentEvictionConditionUSD },
        { G9: this.guarantyPaymentEvictionConditionUSDInWords },
        { G10: this.guarantyPaymentEvictionConditionUAH },
        { G11: this.guarantyPaymentEvictionConditionUAHInWords },
        { W1: this.docsForStorage },
        { W2: this.storageBoxNumber },
        { W3: this.numberWithSpaces(this.agencyCommissionUSD.toFixed(2)) },
        { W4: this.agencyCommissionUSDInWords },
        { W5: this.numberWithSpaces(this.agencyCommissionUAH.toFixed(2)) },
        { W6: this.agencyCommissionUAHInWords },
        { W7: this.saExecutor },
        { W8: this.saExecutorDetailsForSignature },
        { W9: this.saExecutorSignatory },
        { C1: this.executor },
        { C2: this.serviceStartDate },
        { C2D: this.dateToWords(this.serviceStartDate).day },
        { C2M: this.dateToWords(this.serviceStartDate).month },
        { C2Y: this.dateToWords(this.serviceStartDate).year },
        { C3: this.serviceEndDate },
        { C3D: this.dateToWords(this.serviceEndDate).day },
        { C3M: this.dateToWords(this.serviceEndDate).month },
        { C3Y: this.dateToWords(this.serviceEndDate).year },
        { C4: this.finalPaymentDate },
        { C4D: this.dateToWords(this.finalPaymentDate).day },
        { C4M: this.dateToWords(this.finalPaymentDate).month },
        { C4Y: this.dateToWords(this.finalPaymentDate).year },
        { C5: this.executorDetailsForSignature },
        { C6: this.executorSignatory },
        {
          D1F:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.buyerDataBlock.buyerFIO
              : this.seller1DataBlock.sellerFIO,
        },
        {
          D1B:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.buyerDataBlock.buyerBirthday
              : this.seller1DataBlock.sellerBirthday,
        },
        {
          D1D:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.dateToWords(this.buyerDataBlock.buyerBirthday).day
              : this.dateToWords(this.seller1DataBlock.sellerBirthday).day,
        },
        {
          D1M:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.dateToWords(this.buyerDataBlock.buyerBirthday).month
              : this.dateToWords(this.seller1DataBlock.sellerBirthday).month,
        },
        {
          D1Y:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.dateToWords(this.buyerDataBlock.buyerBirthday).year
              : this.dateToWords(this.seller1DataBlock.sellerBirthday).year,
        },
        {
          D1R:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.buyerDataBlock.buyerRNOKPP
              : this.seller1DataBlock.sellerRNOKPP,
        },
        {
          D1A:
            this.whoIsSecondSideCommissionAgreement === 'Покупець'
              ? this.buyerDataBlock.buyerAddress
              : this.seller1DataBlock.sellerAddress,
        }
      );
      return dataObject;
    },
    async renderDoc(dataObject, template) {
      const url = originUrl();
      let out;
      await HTTP.get(url + template, {
        responseType: 'arraybuffer',
      }).then(
        (response) => {
          let zip = new PizZip(response.data);
          let doc = new Docxtemplater(zip, { parser: angularParser });

          doc.setData(dataObject);
          try {
            doc.render();
          } catch (error) {
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (error, key) {
                  error[key] = value[key];
                  return error;
                }, {});
              }
              return value;
            }
            //console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join('\n');
              //console.log('errorMessages', errorMessages);
            }
            throw error;
          }
          out = doc.getZip().generate();
        },
        (error) => {
          if (error) {
            throw error;
          }
        }
      );
      return out;
    },
    async generateAndDownloadDocs() {
      let req = this.formRequestObject();
      let preliminaryAgreement;
      let sellerAgreement;
      let commissionAgreement;

      await this.renderDoc(req, '/templates/preliminaryAgreement.docx').then((response) => {
        preliminaryAgreement = response;
      });
      await this.renderDoc(req, '/templates/sellerAgreement.docx').then((response) => {
        sellerAgreement = response;
      });
      await this.renderDoc(req, '/templates/commissionAgreement.docx').then((response) => {
        commissionAgreement = response;
      });

      let zip = new PizZip();
      zip.file('Предварительный договор.docx', preliminaryAgreement, { base64: true });
      zip.file('Договор с продавцем.docx', sellerAgreement, { base64: true });
      zip.file('Договор комиссия.docx', commissionAgreement, { base64: true });
      let content = zip.generate({ type: 'blob' });
      let zipName =
        this.seller1DataBlock.sellerFIO +
        '-' +
        this.buyerDataBlock.buyerFIO +
        '_' +
        this.agreementDate;
      saveAs(content, zipName + '.zip');
    },
    reset() {
      this.$refs.form.reset();
      this.$nextTick(function () {
        this.sellerCountFrom0 = 0;
        this.$refs.agreementDateRef.parseDataOutside(moment().format('DD.MM.YYYY'));
        this.objectTransferDeadline = 'в день укладання угоди купівлі-продажу';
        this.executor = this.$store.getters['profile/USER'].Executor;
        this.executorDetailsForSignature = this.$store.getters[
          'profile/USER'
        ].ExecutorDetailsForSignature;
      });
    },
  },
};
</script>
