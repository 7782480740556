<template>
  <v-container fluid fill-height pb-10>
    <v-layout justify-center align-center>
      <img :src="require(`@/assets/logo.png`)" width="400" style="max-width: 50%" />
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  beforeMount() {
    this.$router.push('generator');
  },
};
</script>
