<template>
  <login-form redirect="/" :locale-selectable="true" :show-logo="true" logo-style="height:300px;" />
</template>

<script>
import LoginForm from '@/utils/auth/components/LoginForm.vue';

export default {
  components: {
    LoginForm,
  },
};
</script>
