<template>
  <div>
    <div class="label" v-if="!isName">
      <span
        ><b>{{ label }}:</b></span
      >
    </div>
    <div v-if="!inputShow" :style="heightFix">
      <div v-if="!loader">
        <span v-if="isName" class="font-weight-bold h1">{{ getValue }}</span>
        <span v-else-if="getValue !== '' && getValue !== null">
          {{ getValue }}
        </span>
        <span v-else>[Не задано]</span>
        <span class="mdi mdi-square-edit-outline input-toggler" @click="inputBoxShow" />
      </div>
      <div v-else>
        <v-progress-circular indeterminate color="primary" />
      </div>
    </div>
    <div v-if="inputShow">
      <v-form
        ref="input_with_button"
        v-model="valid"
        lazy-validation
        class="input-with-button"
        v-on:submit.prevent=""
      >
        <v-text-field
          :label="labelIfName"
          v-model="getValue"
          :single-line="!isName"
          autofocus
          :maxlength="maxlength"
          :counter="counter"
          @blur="call_Callback"
          @keyup.enter="call_Callback"
          @keydown.esc="escape"
          :rules="validationRulesList"
          :required="required"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'inputWithButton',
  data() {
    return {
      valid: true,
      localValue: '',
      inputShow: false,
      escapeValue: '',
    };
  },
  props: {
    value: {
      type: String,
      //required: true // Нельзя, так как может прийти NULL на поле Email
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    maxlength: {
      type: String,
      required: false,
    },
    counter: {
      type: String,
      required: false,
    },
    openedInputVar: {
      type: Boolean,
      required: true,
    },
    isName: {
      type: Boolean,
      required: false,
    },
    isEmail: {
      type: Boolean,
      required: false,
    },
    loader: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
    labelIfName() {
      if (this.isName) {
        return this.label;
      } else {
        return '';
      }
    },
    heightFix() {
      // Блок имени получается больше на 4 пикселя и тянет всю разметку за собой
      if (this.isName) {
        return 'height: 54px;';
      } else {
        return 'height: 50px;';
      }
    },
    validationRulesList() {
      if (this.required && !this.isEmail) {
        return [(v) => !!v || 'Это обязательное поле!'];
      }
      if (!this.required && this.isEmail) {
        return [
          (v) => {
            if (!v) return true;
            return (
              (v &&
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  v
                )) ||
              'Введите корректный E-mail'
            );
          },
        ];
      }
      if (this.required && this.isEmail) {
        return [
          (v) => !!v || 'Это обязательное поле!',
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || 'Введите корректный E-mail',
        ];
      }
      return [];
    },
  },
  methods: {
    inputBoxShow() {
      if (!this.openedInputVar) {
        this.escapeValue = this.value;
        this.inputShow = true;
        this.localValue = this.value;
        this.$emit('inputBoxSwitch');
      } else {
        this.$toastr.e('Есть открытые и не заполненнные элементы');
      }
    },
    call_Callback() {
      if (!this.$refs.input_with_button.validate()) {
        return;
      }
      this.inputShow = false;
      if (this.localValue === this.value) {
        this.$emit('inputBoxSwitch');
        return; // Если значение не поменялось - ничего не делаем
      }
      this.$emit('inputBoxSwitch');
      // this.$emit('input', this.localValue);
      this.$emit('callback', this.localValue);
    },
    escape() {
      this.localValue = this.escapeValue;
      this.inputShow = false;
      this.$emit('inputBoxSwitch');
    },
  },
};
</script>

<style lang="css">
.h1 {
  font-size: 2.1875rem;
}

.input-toggler {
  cursor: pointer;
  padding-left: 8px;
}
.label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.input-with-button .v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.input-with-button .v-text-field--single-line input {
  padding-top: 0 !important;
  margin-bottom: 3px;
  line-height: 1 !important;
}
</style>
