<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-card>
          <v-tabs>
            <v-tab ripple @click="refreshObjects"> Сотрудники </v-tab>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <users-table ref="users_table" />
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import usersTable from './table/Index';
export default {
  components: {
    usersTable,
  },
  data() {
    return {};
  },
  methods: {
    refreshObjects() {
      if (this.$refs.users_table) {
        this.$refs.users_table.refresh();
      }
    },
  },
};
</script>
