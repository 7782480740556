<template>
  <v-container fluid fill-height pb-10>
    <v-layout justify-center align-center>
      <v-icon large color="red">mdi-cancel</v-icon>
      Нет связи с сервером ;-(
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
