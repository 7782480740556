<template>
  <v-main style="min-height: 100vh">
    <network-error v-show="isNetworkError" />
    <router-view v-show="!isNetworkError" />
    <core-footer v-if="$route.name !== 'Maps'" />
  </v-main>
</template>

<script>
import NetworkError from './NetworkError';

export default {
  components: {
    NetworkError,
  },
  metaInfo() {
    return {
      title: '',
    };
  },
  computed: {
    isNetworkError() {
      return this.$store.getters['auth/networkError'];
    },
  },
};
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
