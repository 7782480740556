let Production_url = window.location.origin + '/api/';
let Production_loginUrl = window.location.origin + '/login'; //rudiment
let Production_originUrl = window.location.origin;

let Development_url = 'http://localhost:8095/api/';
let Development_loginUrl = 'http://localhost:8095/login';
let Development_originUrl = 'http://localhost:8095'; //for docx generator

function url() {
  if (process.env.NODE_ENV === 'production') return Production_url;
  else {
    return Development_url;
  }
}
function loginUrl() {
  if (process.env.NODE_ENV === 'production') return Production_loginUrl;
  else {
    return Development_loginUrl;
  }
}
function originUrl() {
  if (process.env.NODE_ENV === 'production') return Production_originUrl;
  else {
    return Development_originUrl;
  }
}

function productionMarker() {
  return process.env.NODE_ENV === 'production';
}

export { url, loginUrl, originUrl, productionMarker };
